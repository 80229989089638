<script setup>
import { ref } from 'vue';
import { useCategoriesStore } from "../../stores/search";
import ProductsItems from "../Products/Search/ProductsItems.vue";
import ProductsFilters from "../Products/Search/ProductsFilters.vue";
import ProductsSwiperFilters from "../Products/Search/ProductsSwiperFilters.vue";

const searchResultsPageUrl = ref(searchResultsPageUrlUrl);
</script>

<template>
  <!-- TO DO HARDCODED TEXT -->
  <section class="products products--search" aria-label="Producten overzicht met filters" v-if="useCategoriesStore().items && useCategoriesStore().items.length > 0">
    <div class="products__container">
      <section
        class="products__header"
        aria-labelledby="products__header-title"
      >
        <div class="products__header-content" v-if="useCategoriesStore().activeCategory == 'all'">
          <h1 id="products__header-title">
            {{ useCategoriesStore().translations.SEARCH_RESULTS_TITLE}} {{ useCategoriesStore().translations.SEARCH_RESULTS_ALL_PRODUCTS.toLowerCase() }}
          </h1>
        </div>
        <div class="products__header-content" v-else>
          <h1 id="products__header-title">
            {{ useCategoriesStore().translations.SEARCH_RESULTS_TITLE}} {{ useCategoriesStore().activeCategory.toLowerCase() }}
          </h1>
        </div>
        <template v-if="useCategoriesStore().activeCategory == 'all' && Object.keys(useCategoriesStore().categories).length > 1">
          <ProductsSwiperFilters />
        </template>
      </section>
      <aside class="products__filters" aria-label="Product filters" v-if="Object.keys(useCategoriesStore().categories).length > 1">
        <div class="products__filters--desktop">
            <ProductsFilters />
        </div>
        <div class="product__filters--mobile">
            <button class="filters__button">
                <!-- TO DO HARDCODED TEXT -->
                <i class="fa-regular fa-sliders"></i> Producten filteren
            </button>
            <div class="filters__navigation">
                <div class="filters__navigation-head">
                    <!-- TO DO HARDCODED TEXT -->
                    <span class="filters__navigation-title">Filteren</span>
                    <div class="filters__navigation-closer" data-id="nav-closer" aria-expanded="false" aria-controls="mobile-menu">
                        <i class="fa-light fa-xmark"></i>
                    </div>
                </div>
                <div class="filters__navigation-content">
                    <ProductsFilters />
                </div>
            </div>
        </div>
      </aside>

      <ProductsItems />
    </div>
  </section>
  <section class="products__empty" v-else>
      <div class="products__empty-block"> 
        <span class="products__empty-title">{{ useCategoriesStore().translations.NO_RESULTS_TITLE }}</span>
        <p>{{ useCategoriesStore().translations.NO_RESULTS_SUBTITLE }}</p>

        <span class="products__empty-form-title">{{ useCategoriesStore().translations.NO_RESULTS_SEARCHBAR_LABEL }}</span>
        <form :action="searchResultsPageUrl" method="GET">
          <input type="text" placeholder="Zoeken naar..." v-model="keywords" name="keywords" autocomplete="off" /> <!-- TO DO HARDCODED TEXT -->
          <button type="submit"><i class="fa-light fa-search"></i></button>
        </form>

        <span class="products__empty-paragraph-title">{{ useCategoriesStore().translations.NO_RESULTS_TEXT.title }}</span>
        <p v-html="useCategoriesStore().translations.NO_RESULTS_TEXT.content"></p>
      </div>
</section>
</template>

<style lang="scss">
.products__container {
  &:not(:has(.products__filters)) {
    .products__header,
    .products__items {
      @apply col-span-12 col-start-1 row-start-1 
    }

    .products__items {
      @apply row-start-2 row-end-3;
    }

    .product__item {
      @apply col-span-3;
      @apply md:col-span-4;
      @apply sm:col-span-6;
      @apply mob:col-span-12;
    }
  }
}

</style>

<style lang="scss" scoped>
.products{
    @apply mt-12 mb-20;
    @apply md:my-9;
}
.products__container {
  @apply container grid grid-cols-12 gap-3;
  @apply lg:px-6;
  @apply sm:px-4;
}

.products__filters {
  @apply col-span-3 row-start-1 row-end-4 col-start-1;
  @apply md:col-span-12 md:row-start-auto md:row-end-auto md:col-start-auto;
}

.products__header {
  @apply col-span-9 row-start-1 row-end-2 col-start-4 mb-1.5;
  @apply md:col-span-12 md:row-start-auto md:row-end-auto md:col-start-auto;

  h1 {
    @apply text-32_32_20_20 font-fontText font-bold text-black;

    + p {
      @apply mt-5;
      @apply md:mt-2;
    }
  }

  p {
    @apply text-18_28_14_20 text-text;
  }
}

.products__header-content {
    &:has(+ .slider--main-categories) {
    @apply pb-8;
    @apply md:pb-4
  }
}

.products__filters--desktop {
    @apply md:hidden;
}

.product__filters--mobile {
    @apply hidden;
    @apply md:block;
}

.products__empty {
  @apply container grid grid-cols-12 gap-6;
  @apply lg:px-6;
  @apply sm:px-4;
}

.products__empty-block {
  @apply col-span-8 col-start-3 border border-solid border-border p-12 my-9 text-18_28_14_20 text-black;
  @apply md:col-span-12 md:col-start-auto;
  @apply mob:p-6;

  p a {
    &:hover {
      @apply underline;
    }
  }

  .products__empty-title {
    @apply font-bold text-black mb-5 flex text-32_32_18_20;
  }

  .products__empty-form-title {
    @apply text-18_28_14_20 text-black mt-4 block font-bold;
  }

  form {
    @apply w-full relative z-[10] mb-6;

    > input {
        @apply w-full h-[52px] pl-5 pr-16 rounded border border-solid border-border transition-colors duration-300;

        &:focus,
        &:focus-within {
            @apply border-primary outline-none;
        }

        &::placeholder {
            @apply text-[18px] text-[#262626]/50;
        }
    }

    button {
        @apply absolute inset-y-0 right-0 w-[52px] flex items-center justify-center text-[18px] text-dark transition-colors duration-300;
        @apply hover:text-primary;
    }
  }

  .products__empty-paragraph-title {
    @apply flex mb-3;
  }
}
</style>
